<template>
    <div class="grid gap-20">

        <div class="current-report-grid" v-if="report">

            <div class="grid gap-15">
                <p v-if="current">This is an estimate of the amount you will earn based on data we have at the moment.</p>
                <p v-else>This is an amount you shall be paid for the period of <strong>{{ getItemPeriodString(report.period) }}</strong>.</p>
                <div class="current-income-value">
                    <span v-if="report.salary">${{ CONVERT_PRICE(report.salary.toFixed(2)) }}</span>
                    <span v-else>$0.00</span>
                </div>
            </div>

            <div class="current-salary-items-list">
                <div class="current-item-details" :key="key" v-for="(item, key) of report.resources">
                    <div class="name">{{ item.name }}</div>
                    <div class="value">${{ CONVERT_PRICE(item.details.salary.toFixed(2)) }}</div>
                </div>

                <div class="current-item-details" v-if="report.period">
                    <div class="name">Period</div>
                    <div class="value">{{ getItemPeriodString(report.period) }}</div>
                </div>
            </div>

            <div v-if="isLoading(['GetSalaryReport'])" class="spinner-flex"><Spinner size="small"/></div>
        </div>


        <div class="grid">
            
            <div class="grid cols-3 gap-20">
                <StatsItem icon="fa-solid fa-dollar-sign" text="Gross income" :loading="isLoading(['GetSalaryReport'])">
                    <span v-if="report.resources && report.resources.dispatch">${{ CONVERT_PRICE(report.resources.dispatch.details.gross.toFixed(2)) }}</span>
                    <span v-else>$0.00</span>
                </StatsItem>

                <StatsItem icon="fa-solid fa-dollar-sign" text="Net income" :loading="isLoading(['GetSalaryReport'])">
                    <span v-if="report.resources && report.resources.dispatch">${{ CONVERT_PRICE(report.resources.dispatch.details.salary.toFixed(2)) }}</span>
                    <span v-else>$0.00</span>
                </StatsItem>

                <StatsItem icon="fa-solid fa-boxes-stacked" text="Number of loads booked" :loading="isLoading(['GetSalaryReport'])">
                    <span v-if="report.resources && report.resources.dispatch">{{ report.resources.dispatch.details.count }} {{ report.resources.dispatch.details.count === 1 ? 'load' : 'loads' }}</span>
                    <span v-else>0 loads</span>
                </StatsItem>
            </div>
            
        </div>

        <div v-if="report.resources && report.resources.dispatch && report.resources.dispatch.details.loads.length > 0" class="grid gap-15">
            <h3>Booked loads</h3>
            <div class="item-list">
                <TableView :head="loadsTable.head" :size="loadsTable.sizes" :loading="isLoading(['GetSalaryReport'])">
                    <TableRow :size="loadsTable.sizes" :key="item._id" v-for="(item) of report.resources.dispatch.details.loads" @dblclick="loads_view(item._id)">
                        <div class="text-overflow">{{ getLoadDate(item.date) }}</div>
                        <div class="text-overflow">#{{ item.loadId }}</div>
                        <div class="text-overflow"><a href="" @click.prevent>{{ item.carrier.companyName }}</a></div>
                        <div class="text-overflow">{{ item.origin }}</div>
                        <div class="text-overflow">{{ item.destination }}</div>
                        <div class="text-overflow"><strong>${{ CONVERT_PRICE(item.rate.toFixed(0)) }}</strong></div>
                        <div class="text-overflow"><a href="" @click.prevent>{{ item.broker.name }}</a></div>
                        <div class="text-overflow">{{ CONVERT_PRICE(item.userPay.percent.toFixed(2)) }}%</div>
                        <div class="text-overflow income">+ ${{ CONVERT_PRICE(item.userPay.amount.toFixed(2)) }}</div>
                    </TableRow>
                </TableView>
            </div>
        </div>

        

    </div>
</template>

<script>
import moment from 'moment';
import StatsItem from '../StatsItem.vue';
import LoadsJS from '../../mixins/Loads'

    export default {
        mixins: [LoadsJS],
        components: {
            StatsItem
        },
        props: {
            report: { type: Object, required: true },
            current: { type: Boolean },
        },
        data() {
            return {
                loadsTable: {
                    head: [
                        { name: 'Date' },
                        { name: 'Load #' },
                        { name: 'Carrier' },
                        { name: 'Origin' },
                        { name: 'Destination' },
                        { name: 'Rate' },
                        { name: 'Broker' },
                        { name: 'Percent' },
                        { name: 'Income' },
                    ],
                    sizes: [
                        'minmax(50px, 75px)', // date
                        'minmax(50px, 75px)', // load #
                        'minmax(50px, 1fr)', // carrier
                        'minmax(50px, 1fr)', // origin
                        'minmax(50px, 1fr)', // destination
                        '65px', // rate
                        'minmax(50px, 1fr)', // broker
                        '100px', // percent
                        '100px', // income amount
                    ]
                },
            }
        },
        methods: {
            getLoadDate(value) {
                return moment.utc(value).format("ddd D MMM");
            },
            getItemPeriodString(period) {
                if(!period) return;
                let date1 = moment.utc(period.start);
                let date2 = moment.utc(period.end);
                return date1.format("ddd D MMM") + ' - ' + date2.format("ddd D MMM")
            },
        },
    }
</script>

<style lang="scss" scoped>

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
    border-radius: 20px;
}

.income {
    color: $success;
    font-weight: bold;
}

.current-report-grid {
    display: grid;
    gap: 30px;
    padding: 20px 30px;
    background: $themeColor1;
    border-radius: 20px;
    position: relative;
}

.current-income-value {
    font-size: 32px;
    font-weight: 500;
}

.current-salary-items-list {
    display: flex;
    gap: 75px;
}

.current-item-details {
    display: grid;
    row-gap: 5px;
    .name {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 500;
        color: $textShade;
    }
    .value {
        font-size: 24px;
    }
}
</style>