<template>
    <div class="wrapper grid row-50">
        <ActionBar title="Report Details"></ActionBar>

        <ReportDetailsVue :report="report"/>
    </div>
</template>

<script>
import ReportDetailsVue from '../../components/Salary/ReportDetails.vue';

    export default {
        components: {
            ReportDetailsVue
        },
        data() {
            return {
                report: {}
            }
        },
        methods: {
            getCurrentSalary() {
                this.ajax('GetSalaryReport', {
                    method: 'get',
                    url: `/mySalary/report/${this.$route.params.id}`,
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                        return;
                    }
                    this.report = body;
                });
            },
        },
        mounted() {
            this.getCurrentSalary();
        }
    }
</script>

<style lang="scss" scoped>
.wrapper {
    max-width: 1300px;
    margin: 0 auto;
}
</style>